import { BaseDto } from '@/shared/dtos/base-dto';

export class payment_data extends BaseDto {
    public nombre !: string;
    public apellidos!:string;
    public telefono!:string;
    public correo!:string;
    public producto!:string;
    public precio!:number;
    public nif!:string;
    public cupon !:string;
} 
