

























































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { payment_data } from "./payment_data";
import {
  DxAdapter,
  DxEmailRule,
  DxRequiredRule,
  DxNumericRule,
} from "devextreme-vue/validator";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxValidationSummary } from "devextreme-vue/validation-summary";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import "@/assets/css/dx.material.my-medical-diet-theme.css";
import "devextreme/dist/css/dx.common.css";
import "@/assets/scss/main.scss";
import DxButton from "devextreme-vue/button";
import { RouterNames } from "@/router/routernames";
import { cupon_descuentoDto } from "@/shared/dtos/payments/cupon_descuentoDto";
@Component({
  components: {
    PayPalCheckoutVue: () => import("./components/PaypalCheckoutTs.vue"),
    DxTextBox: () => import("devextreme-vue/text-box"),
    DxValidationGroup,
    DxValidator: () => import("devextreme-vue/validator"),
    DxAdapter,
    DxEmailRule,
    DxRequiredRule,
    DxValidationSummary,
    DxNumericRule,
    DxButton,
  },
})
export default class Paypal_payments_renovar extends Vue {
  /*private paypal = {
    sandbox:
      "AeIr9_c2anQpb06c4Hbd19GcoFTdpQm2L3rxEn2g2eKbB58PvfPJj-vsOYeJbJjIkXD6szPDnNJybMrv",
    production:
      "AWBb4yJZQbvUMje9Ke9eDoBuzvCWwXnuRH3-9AodqDPwH4n6fNyoj8IJswwcB5pkwWiIjBkX7ihJVFgo",
  };*/

  public data_payment: payment_data = new payment_data({
    producto: "",
    precio: 0,
    nombre: "",
    apellidos: "",
    telefono: "",
  });
  public cupon_descuento: cupon_descuentoDto = new cupon_descuentoDto({
    cupon: "",
  });
  public show_finish: boolean = false;
  public datos_validos: boolean = false;
  public unidades_productos: number = 1;
  @Ref("customValidator") readonly validacion!: DxValidationGroup;
  //update:items
  beforeCreate() {}
  mount() {}
  async created() {
    const esMessages = await import("devextreme/localization/messages/es.json");
    const { locale, loadMessages } = await import("devextreme/localization");

    loadMessages(esMessages);

    locale("es");

    this.getLicencia();
    if (this.$route.query.paymentCompleted !== undefined) {
      if (this.$route.query.paymentCompleted === "true") {
        this.show_finish = true;
      }
    }
    if (this.$route.params.correo !== undefined) {
      this.data_payment.correo = this.$route.params.correo;
      this.change_input(null, 1);
    }
  }

  public getLicencia() {
    ssmHttpService
      .get(API.MymedicaldietIntegrations + "/licencia/" + this.$route.params.id)
      .then((x: any) => {
        this.data_payment.producto = x.nombre_articulo;
        this.data_payment.precio = x.precio;
        this.cupon_descuento.id_licencia = x.id;
      });
    this.unidades_productos = 1;
  }

  public get parameter_change() {
    ssmHttpService
      .get(API.MymedicaldietIntegrations + "/licencia/" + this.$route.params.id)
      .then((x: any) => {
        this.data_payment.producto = x.nombre_articulo;
        this.data_payment.precio = x.precio;
      });
    if (this.$route.query.paymentCompleted !== undefined) {
      if (this.$route.query.paymentCompleted === "true") {
        this.show_finish = true;
      }
    }
    return ".";
  }

  public goToLogin() {
    //this.$router.push({ name: RouterNames.Login });
    //document.open("https://app.mymedicaldiet.es/#/login");

    try {
      window.parent.postMessage("HELLO_PARENT", "https://mymedicaldiet.es");
    } catch (error) {}
    this.$router.push({ name: RouterNames.Login });
  }

  public change_input(event: any, id: number) {
    ssmHttpService.post(
      API.MymedicaldietIntegrations + "/paso_finalizar_compra/",
      JSON.stringify(this.data_payment),
      false
    );
    if (id === 1) {
      ssmHttpService
        .post(
          API.MymedicaldietIntegrations + "/data_customer/",
          JSON.stringify(this.data_payment),
          true,
          false,
          true
        )
        .then((x: payment_data) => {
          this.data_payment.nombre = x.nombre;
          this.data_payment.apellidos = x.apellidos;
          this.data_payment.telefono = x.telefono;
          this.datos_validos = true;
        })
        .catch(() => {
          this.data_payment.nombre = "";
          this.data_payment.apellidos = "";
          this.data_payment.telefono = "";
          this.datos_validos = false;
        });
    } else if (id === 0) {
      ssmHttpService
        .post(
          API.MymedicaldietIntegrations + "/validar_cupon/",
          JSON.stringify(this.cupon_descuento),
          true,
          false,
          true
        )
        .then((x: any) => {
          this.unidades_productos = x.unidades;
          this.data_payment.precio = x.precio;
        })
        .catch((e: any) => {
          //await this.getLicencia();
        });
    }
  }

  paymentCompleted(data: any) {
    this.data_payment.cupon = this.cupon_descuento.cupon;
    ssmHttpService
      .post(
        API.MymedicaldietIntegrations + "/pago/",
        JSON.stringify({ customer: this.data_payment, payment: data })
      )
      .then(() => {
        this.show_finish = true;
        this.$route.query.paymentCompleted = "true";
      });
  }
  paymentCancelled(data: any) {}
  paymentClick(data: any, actions: any) {
    if (this.validacion.instance!.validate().isValid) {
      if (this.data_payment.precio === 0) {
        this.paymentCompleted(null);
        return;
      }
      return actions.resolve();
    } else {
      return actions.reject();
    }
  }
  paymentInit(actions: any) {}
}
